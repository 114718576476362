import React from 'react';
import { Card, Typography, Box, Stack, Link, Avatar, Chip, Divider } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { RatingLabel } from 'src/components';
import { useRouter } from 'next/router';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import timeIcon from '@iconify/icons-carbon/time';
import { getLevelIcon } from 'src/utils/getIcon';
import { Image, Label, Iconify, TextMaxLine, TextIconLabel } from 'src/components';
import { Constants } from 'src/sections/constants';

const useStyles = makeStyles(() => ({
	CardContent: {
		padding: '0px',
	},
	price: {
		textAlign: 'end',
	},
	gigContent: {
		padding: '20px',
	},
	ratingBox: {
		marginTop: '10px',
	},
	gigTitle: {
		color: '#fa541c',
	},
	experience: {
		textAlign: 'start',
		color: '#919eab',
	},
}));

const ServiceCard = ({ service }) => {
	const { title, charges, media, ratings, experience, projectSubcategory, projectCategory, userMinorProfile, rating, tags } = service;
	const router = useRouter();
	function handleCardClick() {
		router.push({
			pathname: `/auth/freelancer/gig/${service._id}`,
		});
	}
	const classes = useStyles();
	function removeHtmlTags(text) {
		const regex = /<[^>]*>/g;
		const cleanedText = text?.replace(regex, '');
		return cleanedText;
	}
	// const description = removeHtmlTags(service.description);

	return (
		<>
			<Link cursor="pointer" underline="none" onClick={handleCardClick}>
				<Card
					sx={{
						// display: { sm: 'flex' },
						boxShadow: (theme) => theme.customShadows.z16,
						'&:hover': {
							boxShadow: (theme) => theme.customShadows.z24,
						},
						// ...(vertical && {
						//   flexDirection: 'column',
						// }),
					}}>
					<Box
						sx={{
							flexShrink: { sm: 0 },
							height: '250px',
							width: '100%',
							overflow: 'hidden',
						}}>
						<div
							style={{
								height: '100%',
								width: '100%',
								backgroundImage: `url(${service.media?.images[1] || Constants.fSearchCardImagePlaceholder})`,
								backgroundSize: '100% 100%',
								backgroundPosition: 'center',
							}}
						/>
					</Box>

					{service.experience && (
						<Label color="warning" variant="filled" sx={{ top: 12, left: 12, position: 'absolute', textTransform: 'uppercase' }}>
							Best Seller
						</Label>
					)}

					{/* <Stack
							spacing={{
								xs: 1,
								// sm: true ? 3 : 1,
							}}> */}
					{/* <Stack direction="row" alignItems="center" justifyContent="space-between"> */}
					{/* <Typography variant="overline" sx={{ color: 'primary.main' }}>
									{service.projectCategory} | {service.projectSubcategory}
								</Typography> */}
					{/* <Typography variant="h4" sx={{ fontSize: '22px' }}> */}
					{/* {service.charges.minAmount > 0 && (
                <Box
                  component="span"
                  sx={{ color: 'text.disabled', textDecoration: 'line-through' }}
                >
                  {service.charges.minAmount}
                </Box>
              )}{' '} */}
					{/* ${service.charges.minAmount}
									{service.charges.currency}/{service.charges.unit}
								</Typography>
							</Stack> */}

					{/* <Stack spacing={1}> */}
					{/* <NextLink
              passHref
              as={Routes.eLearning.course(id)}
              href={Routes.eLearning.course('[id]')}
            > */}
					{/* <TextMaxLine variant="h6" style={{ fontSize: '17px' }} line={1} asLink> */}
					{/* <Stack direction="row" alignItems="center" justifyContent="space-between"> */}
					{/* {service.title} */}
					{/* <Typography style={{ fontSize: '17px', color: '#FA541C', fontWeight: 'bold' }}>
											${service.charges.minAmount}
											{service.charges.currency}/{service.charges.unit}
										</Typography> */}
					{/* </Stack> */}
					{/* </TextMaxLine> */}
					{/* </NextLink> */}

					{/* <TextMaxLine
									variant="body2"
									color="text.secondary"
									line={2}
									sx={
										{
											// ...(vertical && {
											//   display: { sm: 'none' },
											// }),
										}
									}>
									{description}
								</TextMaxLine> */}
					{/* </Stack> */}
					{/* </Stack> */}

					<Stack spacing={2} sx={{ p: 3 }}>
						<TextMaxLine variant="h6" style={{ fontSize: '17px' }} line={1} asLink>
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								{service.title}
								{/* <Typography style={{ fontSize: '17px', color: '#FA541C', fontWeight: 'bold' }}>
											${service.charges.minAmount}
											{service.charges.currency}/{service.charges.unit}
										</Typography> */}
							</Stack>
						</TextMaxLine>
						<TextIconLabel
							icon={<Avatar src={userMinorProfile.avatar} />}
							value={
								<div style={{ display: 'flex' }}>
									<div>
										<Typography variant="body2" sx={{ ml: 2 }}>
											{userMinorProfile.userName}
										</Typography>
										<Typography variant="body1" sx={{ ml: 2, mb: -1, mt: -1 }} fontWeight={500}>
											{[userMinorProfile.firstName, userMinorProfile.lastName].join(' ')}
										</Typography>
									</div>
									<Typography style={{ fontSize: '16px', marginLeft: '22px', display: 'flex', alignItems: 'center', color: '#FA541C', fontWeight: 'bold' }}>
										${service.charges.minAmount}
										{service.charges.currency}/{service.charges.unit}
									</Typography>
								</div>
							}
						/>

						{/* <Divider
							sx={{
								borderStyle: 'dashed',
								display: { sm: 'none' },
								// ...(vertical && {
								//   display: 'block',
								// }),
							}}
						/> */}

						<Stack
							direction="row"
							flexWrap="wrap"
							alignItems="center"
							sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 1.5 } }}
							divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}>
							{/* <TextIconLabel icon={<Iconify icon={timeIcon} sx={{ width: 20, height: 20, mr: 1 }} />} value={`20 hr`} /> */}
							<TextIconLabel icon={getLevelIcon(1)} value={service.experience} />
							<Stack spacing={2.5} direction="row" alignItems="center" flexWrap="wrap" divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}>
								<RatingLabel ratings={4.5} />
								<Stack direction="row" sx={{ typography: 'subtitle2' }}>
									80
									<Box component="span" typography="body2" sx={{ ml: 0.5 }}>
										Orders
									</Box>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Card>
			</Link>
		</>
	);
};

export default ServiceCard;
