// import { Box, Container } from '@mui/system';
import CustomCareerJobItem from 'src/AppComponents/CustomCareerJobItem';
import { ErrorScreen, LoadingScreen, Page } from 'src/components';
import React, { useEffect, useState } from 'react';
// import ProjectDetailModal from 'src/AppComponents/ProjectDetailModal';
// import { useRequest } from 'src/hooks';
// import freelancerService from 'src/api_/freelancerService';
import useFetch, { useFetchPost, useFetch_v3 } from 'src/hooks/useFetch';
import { Endpoint } from 'src/api_/urls';
// import ServiceCard from 'src/sections/profile/freelancer/preview/_ServiceTab/ServiceCard';
import { Box, Stack, Typography, Pagination } from '@mui/material';

// import { CareerJobBarFilters } from 'src/sections/@career';
import { FreelancerSearchFilter } from './filters';
import freelancerService from 'src/api_/freelancerService';
import { useSelector } from 'react-redux';
import ServiceCard from './ServiceCard';

const RelatedLink = ({ title }) => (
	<Typography
		variant="caption"
		sx={{
			cursor: 'pointer',
			color: 'info.main',
			'&:hover': {
				color: 'info.dark',
			},
		}}>
		{title}
	</Typography>
);

function DashboardFreelancer() {
	// const [searchResponse, setSearchResponse] = useState(null);
	// const [loading, setLoading] = useState(true);
	const [searchResponse, setSearchResponse] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);

	const searchFilter = {
		// tags: ['JAVASCRIPT', 'REACTJS', 'IOS'],
		tags: [],
	};
	// const user = useSelector((state) => state.auth.authData);

	// const { data: profile, error: profileError, isValidating: isFetchingUser } = useFetch({ url: `/api/auth/client/${user?.public_id}` }, { revalidateOnFocus: false });

	// const { data: profile, error: profileError, isValidating: isFetchingUser } = useFetchPost({ url: `/api/auth/client/${user?.public_id}` }, { revalidateOnFocus: false });
	useEffect(() => {
		freelancerService
			.getFreelancerSearch(searchFilter)
			.then((res) => {
				setSearchResponse(res.results);
			})
			.catch((e) => e);
	}, []);

	if (!searchResponse) {
		return <LoadingScreen />;
	}

	const itemsPerPage = 12; // Number of items per page
	const totalItems = searchResponse.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const displayedItems = searchResponse.slice(startIndex, endIndex);

	// if (searchError) {
	// 	return <ErrorScreen />;
	// }
	return (
		<Box>
			<Box sx={{ marginTop: '1rem' }}>
				<FreelancerSearchFilter />
			</Box>
			<Stack direction="row" spacing={2.6} alignItems="center" mt={2}>
				<Typography variant="body2">Similar Searches</Typography>

				<Stack direction="row" spacing={1} alignItems="center">
					<RelatedLink title="wordpress developer" />,
					<RelatedLink title="theme developer" />,
					<RelatedLink title="UI/UX developer" />
					<RelatedLink title="wordpress developer" />,
				</Stack>
			</Stack>

			<Box
				sx={{
					display: 'grid',
					rowGap: { xs: 12, sm: 2, md: 6 },
					columnGap: 4,
					margin: 3,
					gridTemplateColumns: {
						xs: 'repeat(1, minmax(0, 1fr))', // On extra-small screens, show 1 card full width
						sm: 'repeat(1, minmax(0, 1fr))', // On small screens, show 1 card full width
						md: 'repeat(3, minmax(0, 1fr))', // On medium screens, show 3 cards in a row
						lg: 'repeat(3, minmax(0, 1fr))', // On large screens, show 3 cards in a row
					},
				}}>
				{displayedItems?.map((service, i) => (
					<ServiceCard service={{ ...service, user: {} }} key={i} />
				))}
			</Box>
			<Pagination sx={{ display: 'flex', justifyContent: 'center', mt: 6 }} count={totalItems} page={currentPage} onChange={(event, page) => handlePageChange(page)} />
		</Box>
	);
}

export default DashboardFreelancer;
